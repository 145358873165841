import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import Information from "pages/LandingPages/AboutUs/sections/Information";
// import Team from "pages/LandingPages/AboutUs/sections/Team";
// import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";

import routes from "routes";
import footerRoutes from "footer.routes";

import bgImage from "assets/images/bg-about-us.jpg";
import { TypeAnimation } from "react-type-animation";
import TeamImc from "./sections/TeamImc";
import SuccessPath from "./sections/SuccessPath";
import { useNavigate } from "react-router-dom";

function AboutUs() {
  const navigate = useNavigate();
  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              <TypeAnimation
                preRenderFirstString={true}
                sequence={[
                  500,
                  'Learn From an International Master', // initially rendered starting point
                  1000,
                  'Learn From an IM Manush Shah',
                  1000,
                  'Learn From an International Master',
                  1000,
                  'Learn From an IM Manush Shah',
                  500,
                ]}
                speed={50}
                // style={{ fontSize: '2em' }}
                repeat={Infinity}
              />
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              We&apos;re constantly trying to express ourselves and actualize our dreams. If you
              have the opportunity to play this game
            </MKTypography>
            <MKButton color="default" onClick={() => navigate('/contact-us')} sx={{ color: ({ palette: { dark } }) => dark.main }}>
              Join Now
            </MKButton>
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              Find us on
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography component="a" variant="body1" color="white" target="_blank" href="https://www.facebook.com/profile.php?id=61566587885208" mr={3}>
                <i className="fab fa-facebook" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" target="_blank" href="https://www.instagram.com/imchess_academy?igsh=YWhzZTY0bHQyODc4&utm_source=qr" mr={3}>
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" target="_blank" href="https://chat.whatsapp.com/KCVQr2D3Z3I119uN5t98OD" mr={3}>
                <i className="fab fa-whatsapp" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" target="_blank" href="https://www.youtube.com/@IMCHESS_Era">
                <i className="fab fa-youtube" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        {/* <Team /> */}
        {/* <Featuring /> */}
        <SuccessPath />
        <TeamImc />
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;