import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";

// import Contact from "../Author/sections/Contact";
import { Paper, Typography } from "@mui/material";
import bgContactus from "assets/images/bg-contactus.jpg";

function NavContactUs() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgContactus})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography variant="h2" color="white" mt={8} mb={1}>
                            Get In Touch With Us Through Our Contact Details.
                        </MKTypography>
                        <MKTypography variant="h6" color="white" mt={8} mb={1}>
                            Find us on
                        </MKTypography>
                        <MKBox display="flex" justifyContent="center" alignItems="center">
                            <MKTypography component="a" variant="body1" color="white" target="_blank" href="https://www.facebook.com/profile.php?id=61566587885208" mr={3}>
                                <i className="fab fa-facebook" />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" target="_blank" href="https://www.instagram.com/imchess_academy?igsh=YWhzZTY0bHQyODc4&utm_source=qr" mr={3}>
                                <i className="fab fa-instagram" />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" target="_blank" href="https://chat.whatsapp.com/KCVQr2D3Z3I119uN5t98OD" mr={3}>
                                <i className="fab fa-whatsapp" />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" target="_blank" href="https://www.youtube.com/@IMCHESS_Era">
                                <i className="fab fa-youtube" />
                            </MKTypography>
                        </MKBox>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Paper
                            sx={{
                                justifyContent: 'center',
                                textAlign: 'center',
                                p: 4,
                                color: '#000',
                                backgroundColor: '#fff',
                                borderRadius: '0px 5px 10px rgba(0, 0, 0, 0.1)',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            }}
                        >
                            <Typography variant="h4" gutterBottom color={'#86c33a'}>
                                <i className="fas fa-map-marker-alt" />
                            </Typography>
                            <Typography variant="h4" gutterBottom color={'#86c33a'}>
                                OUR ADDRESS
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Suvidha shopping center, paldi
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Ahmedabad
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Paper
                            sx={{
                                justifyContent: 'center',
                                textAlign: 'center',
                                p: 4,
                                color: '#000',
                                backgroundColor: '#fff',
                                borderRadius: '0px 5px 10px rgba(0, 0, 0, 0.1)',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            }}
                        >
                            <Typography variant="h4" gutterBottom color={'#86c33a'}>
                                <i className="fas fa-phone" />
                            </Typography>
                            <Typography variant="h4" gutterBottom color={'#86c33a'}>
                                PHONE NUMBER
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                +91 9664854716
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                +91 8200802241
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Paper
                            sx={{
                                justifyContent: 'center',
                                textAlign: 'center',
                                p: 4,
                                color: '#000',
                                backgroundColor: '#fff',
                                borderRadius: '0px 5px 10px rgba(0, 0, 0, 0.1)',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            }}
                        >
                            <Typography variant="h4" gutterBottom color={'#86c33a'}>
                                <i className="fas fa-envelope" />
                            </Typography>
                            <Typography variant="h4" gutterBottom color={'#86c33a'}>
                                MESSAGE US
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                admin@imchessacademy.com
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                imchess41@gmail.com
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                {/* <Contact /> */}
            </Card >

            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default NavContactUs;