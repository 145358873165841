import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './style.css';

// import required modules
import { Parallax, Pagination, Navigation } from 'swiper/modules';

export default function ContentSwipper() {
    return (
        <>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                speed={600}
                parallax={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Parallax, Pagination, Navigation]}
                className="mySwiper"
            >
                <div
                    slot="container-start"
                    className="parallax-bg"
                    style={{
                        'background-image':
                            'url(https://i.pinimg.com/564x/b0/e6/5e/b0e65ee0a62375f8348376fd3a7aa86b.jpg)',
                    }}
                    data-swiper-parallax="-23%"
                ></div>
                <SwiperSlide>
                    <div className="title" data-swiper-parallax="-300">
                        Tournament Guidance
                    </div>
                    {/* <div className="subtitle" data-swiper-parallax="-200">
                        Subtitle
                    </div> */}
                    <div className="text" data-swiper-parallax="-100">
                        <p>
                            - Preparation Before the Tournament
                        </p>
                        <p>
                            - Setting Goals
                        </p>
                        <p>
                            - Mental and Physical Fitness
                        </p>
                        <p>
                            - During the Tournament (Do's & Don'ts)
                        </p>
                        <p>
                            - Analyzing Games
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="title" data-swiper-parallax="-300">
                        Beginners Criteria
                    </div>
                    <div className="subtitle" data-swiper-parallax="-200">
                        Unrated
                    </div>
                    <div className="text" data-swiper-parallax="-100">
                        <p>
                            -  Little to no prior experience with chess.
                        </p>
                        <p>
                            -  Understanding of basic chess rules like piece movement, capturing, and checkmate.
                        </p>
                        <p>
                            -  Desire to learn fundamental strategies and game principles.
                        </p>
                        <p>
                            - Familiarity with chess notation and basic tactics (pins, forks, skewers).
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="title" data-swiper-parallax="-300">
                        Intermediate Criteria
                    </div>
                    <div className="subtitle" data-swiper-parallax="-200">
                        Unrated to 1500 || 1500 to 1800
                    </div>
                    <div className="text" data-swiper-parallax="-100">
                        <p>
                            - Understanding of common openings and basic endgame techniques.
                        </p>
                        <p>
                            - Played in local or online tournaments and looking to improve strategy and planning.
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="title" data-swiper-parallax="-300">
                        Advance Criteria
                    </div>
                    <div className="subtitle" data-swiper-parallax="-200">
                        1800+ Rating || 2200+(title preparation)
                    </div>
                    <div className="text" data-swiper-parallax="-100">
                        <p>
                            - Strong grasp of advanced tactics, complex openings, and endgame theory.
                        </p>
                        <p>
                            - Regular participation in rated tournaments or holding an established rating (e.g., FIDE or national rating).
                        </p>
                        <p>
                           - Aspiring to refine skills through in-depth analysis, preparation for high-level tournaments, and advanced psychological training.
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="title" data-swiper-parallax="-300">
                        Personalised Guidance
                    </div>
                    {/* <div className="subtitle" data-swiper-parallax="-200">
                        Subtitle
                    </div> */}
                    <div className="text" data-swiper-parallax="-100">
                        <p>
                            - Customized Training Plans
                        </p>
                        <p>
                            - Game Analysis
                        </p>
                        <p>
                            - Tactical and Strategic Development
                        </p>
                        <p>
                            - Opening Preparation
                        </p>
                        <p>
                            - Mental and Psychological Support
                        </p>
                        <p>
                            - Goal-Oriented Approach
                        </p>
                        <p>
                            - One-on-One Sessions
                        </p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
