import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";

import { TypeAnimation } from "react-type-animation";
import bgPerks from "assets/images/bg-perks.jpg"
import TransparentBlogCard from 'examples/Cards/BlogCards/TransparentBlogCard';
import { postsData } from 'pages/LandingPages/Author/sections/postsData';
import { videoPost } from 'pages/LandingPages/Author/sections/postsData';
function NewsBlogs() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="40vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgPerks})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h4"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            <TypeAnimation
                                preRenderFirstString={true}
                                sequence={[
                                    500,
                                    `Words that Matter.`, // initially rendered starting point
                                    1000,
                                    `A Journey Through News and Blogs!`,
                                    1000,
                                    `Words that Matter.`,
                                    1000,
                                    `A Journey Through News and Blogs!`,
                                    500,
                                ]}
                                speed={50}
                                // style={{ fontSize: '2em' }}
                                repeat={Infinity}
                            />
                        </MKTypography>

                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Beyond the Headlines: Exploring Deeper Perspectives!
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <Container>
                    <Grid container spacing={3}>
                        {postsData?.map((post) => {
                            return <Grid item xs={12} lg={4}>
                                <TransparentBlogCard
                                    image={post?.image}
                                    title={post?.title}
                                    description={post?.description}
                                    action={{
                                        type: "internal",
                                        route: post?.route,
                                        color: "info",
                                        label: "read more",
                                    }}
                                />
                            </Grid>
                        })}
                        {videoPost?.map((video) => {
                            return <Grid item xs={12} lg={4}>
                                {video?.image ?
                                    <TransparentBlogCard
                                        isVideo={false}
                                        image={video?.image}
                                        title={video?.title}
                                        action={{
                                            type: "internal",
                                            route: video?.route,
                                            color: "info",
                                            label: "Watch Video",
                                        }}
                                    /> :
                                    <TransparentBlogCard
                                        isVideo={true}
                                        title={video?.title}
                                        action={{
                                            type: "internal",
                                            route: video?.route,
                                            color: "info",
                                            label: "Watch Video",
                                        }}
                                    />
                                }

                            </Grid>
                        })}

                        {/* <Grid item xs={12} lg={4}>
                            <TransparentBlogCard
                                isVideo={true}
                                title="Men's day"
                                action={{
                                    type: "internal",
                                    route: "https://www.youtube.com/embed/xTAY5u1s_C4",
                                    color: "info",
                                    label: "read more",
                                }}
                            />
                        </Grid> */}
                    </Grid>
                </Container>
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default NewsBlogs;