import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
// import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
// import Testimonials from "pages/Presentation/sections/Testimonials";
import Posts from "pages/LandingPages/Author/sections/Posts";
import ChessBenefits from "pages/Presentation/components/ChessBenefits";
import routes from "routes";
import footerRoutes from "footer.routes";

// import bgImage from "assets/images/bg-presentation.jpg";
import BriefAboutus from "./sections/BriefAboutus";
import WhyUs from "./sections/WhyUs";
// import Contact from "pages/LandingPages/Author/sections/Contact";
import { Divider, Icon } from "@mui/material";
import MKButton from "components/MKButton";
import { useNavigate } from "react-router-dom";

function Presentation() {
  const navigate = useNavigate();
  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <MKBox
        minHeight="110vh"
        width="100%"
        sx={{
          backgroundImage: `url(https://i.pinimg.com/originals/4c/ab/1e/4cab1e3fa8397410807684226e47e7fe.gif)`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              IMCHESS Academy{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
            The upcoming era of champions in the growing chess world.
            Chase & Checkmate
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -10,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <BriefAboutus />
        {/* <Divider sx={{ my: 2 }} /> */}
        <WhyUs />
        <Pages />
        {/* <Testimonials /> */}
        <Posts />
        <Information />
        <ChessBenefits />
        {/* <DesignBlocks /> */}
        <Divider sx={{ my: 2 }} />
        <Container sx={{ mt: 6 }}>
        </Container>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography variant="h2">Checkout our best courses</MKTypography>
            <MKTypography variant="body1" color="text" mb={4}>
              Explore and Unlock new possibilities and embark on a journey of learning and growth with us.
            </MKTypography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="deblur"
                title="Basic"
                description="Check the possible ways of working with our product and the necessary files for building your own project."
                action={{
                  type: "external",
                  route: "/",
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <FilledInfoCard
                color="info"
                icon="deblur"
                title="Intermediate"
                description="Get inspiration and have an overview about the plugins that we used to create the Material Kit."
                action={{
                  type: "external",
                  route: "/",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="deblur"
                title="Advance"
                description="Check the possible ways of working with our product and the necessary files for building your own project."
                action={{
                  type: "external",
                  route: "/",
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <FilledInfoCard
                color="info"
                icon="deblur"
                title="Individual"
                description="Material Kit is giving you a lot of pre-made components, that will help you to build UI's faster."
                action={{
                  type: "external",
                  route: "/",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={3} justifyContent="center">
          <MKButton variant="gradient" color="info"
            onClick={() => navigate('/batch/online')}
            sx={{
              width: "max-content",
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}>
            View All Courses <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKButton>
        </Grid>
        {/* <MKBox component="section" py={{ xs: 0, sm: 12 }}>
          <MKBox
            variant="gradient"
            bgColor="dark"
            position="relative"
            borderRadius="xl"
            sx={{ overflow: "hidden" }}
          >
            <Contact />
          </MKBox>
          <Container>
          </Container>
        </MKBox> */}

        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  {/* Choose Your Future Wisely! */}
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  To know more, follow us on social media.
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://www.instagram.com/imchess_academy?igsh=YWhzZTY0bHQyODc4&utm_source=qr"
                  target="_blank"
                  color="instagram"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-instagram" />
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://chat.whatsapp.com/KCVQr2D3Z3I119uN5t98OD"
                  target="_blank"
                  color="whatsapp"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-whatsapp" />
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/profile.php?id=61566587885208"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.youtube.com/@IMCHESS_Era"
                  target="_blank"
                  color="youtube"
                >
                  <i className="fab fa-youtube" />
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
