import Container from "@mui/material/Container";

// React components
import MKBox from "components/MKBox";
import FeaturesOne from "layouts/sections/page-sections/featuers/components/FeaturesOne";

function ChessBenefits() {
  const bgImage = "https://i.pinimg.com/564x/35/ad/6e/35ad6ec49f7f64dd27c1b2e7da4f8a8b.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={12}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0.9),
            rgba(gradients.success.state, 0.9)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <FeaturesOne
          color="white"
          title="BENEFITS OF CHESS"
          description="Chess is a powerful game that enhances critical thinking, problem-solving, and decision-making skills. It improves concentration, fosters creativity, and strengthens memory while teaching patience and resilience. As a mental workout, it helps individuals develop strategic thinking and adapt to challenges, making it a game that nurtures both the mind and character."
          button=""
          point1="Boosts memory and sharpens focus."
          point2="Enhances decision-making and problem-solving."
          point3="Builds strategic thinking and mental resilience."
        />
      </Container>
    </MKBox>
  );
}

export default ChessBenefits;
