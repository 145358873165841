import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { Divider, Stack } from "@mui/material";
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import M13 from "assets/images/studs/M13.jpg";
import M8 from "assets/images/studs/M8.jpg";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={M13} alt="Burce Mars" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={10} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">IM Manush Shah</MKTypography>
                <MKButton variant="outlined" color="info" size="small">
                  <MKTypography
                    component="a"
                    target="_blank"
                    href="https://www.instagram.com/imchess_academy?igsh=Mnl3aXl6NzZ6d3Bu"
                    variant="body1"
                    fontWeight="bold"
                    color="info"
                    sx={{
                      width: "max-content",
                      display: "flex",
                      alignItems: "center",

                      "& .material-icons-round": {
                        transform: `translateX(3px)`,
                        transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                      },

                      "&:hover .material-icons-round, &:focus .material-icons-round": {
                        transform: `translateX(6px)`,
                      },
                    }}
                  >
                    Follow <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </MKTypography>
                </MKButton>
              </MKBox>
              <Grid container spacing={3} mb={3}>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    20+ Years&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Chess Experience
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    4+ Years&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Coaching Experience
                  </MKTypography>
                </Grid>
                {/* <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    260&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Following
                  </MKTypography>
                </Grid> */}
              </Grid>
              <MKTypography variant="body1" fontWeight="light" color="text">
                Manush Shah, a seasoned <b>International Chess Master</b>, boasts over <b>2 decades</b> of experience both playing and coaching chess.
                His journey began in his youth, leading him to excel as a player and transition into a respected coach, guiding aspiring enthusiasts to unleash their potential.
                Manush's enduring dedication and expertise make him a beacon in the world of chess, inspiring generations to come. <br />
                <MKTypography
                  component="a"
                  href="news-blogs"
                  variant="body1"
                  fontWeight="bold"
                  color="info"
                  mt={3}
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                  View Achievements <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </MKTypography>
              <Divider />
              <MKTypography variant="h4" fontWeight="light" color="black">
                Playing Career
              </MKTypography> <br />
              <MKTypography variant="body2" fontWeight="light" color="text">
                Manush's illustrious career in chess spans over two decades, characterized by unparalleled success both nationally and internationally. His exceptional talent and dedication have propelled him to remarkable heights, solidifying his reputation as one of India's finest chess players.
              </MKTypography>
              <br />
              <MKTypography variant="body2" fontWeight="light" color="text">
                Over the course of his career, Manush has etched his name in history, claiming the title of <b>Gujarat State Chess Champion an astounding 18 times</b> across various categories.
                This feat stands as a testament to his unwavering commitment to excellence and his unparalleled mastery of the games.
              </MKTypography>
              <br />
              <MKTypography variant="body2" fontWeight="light" color="text">
                Furthermore, Manush's prowess extends beyond the borders of his home state, as he has triumphed in All India rated tournaments on three separate occasions. These victories underscore his dominance on the national stage and highlight his ability to compete at the highest level.
              </MKTypography>
              <br />
              <MKTypography variant="body2" fontWeight="light" color="text">
                Representing India in competitions held across <b>24 different countries</b>, Manush has not only showcased his extraordinary skill but has also played a pivotal role in promoting the game of chess on the global platform.
                His experiences facing off against top players from diverse backgrounds have enriched his understanding of the game, shaping him into a well-rounded and formidable chess professional.
              </MKTypography>
              <br />
              <MKTypography variant="body2" fontWeight="light" color="text">
                In addition to his achievements on the chessboard, Manush's dedication to the sport and his unwavering passion for excellence serve as an inspiration to aspiring chess players across the nation and remains a true embodiment of talent, perseverance, and sportsmanship.
              </MKTypography>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <MKBox mb={1}>
                    <HorizontalTeamCard
                      image={M8}
                      name="Coaching Experience"
                      position={{ color: "info", label: "Chess coaching: shaping champions" }}
                      description="With four years of coaching experience, Manush Shah is your go-to mentor for chess excellence. 
                      His approach combines technical prowess, strategic insight, and unwavering support, ensuring every student reaches their full potential."
                    />
                  </MKBox>
                </Grid>
              </Grid>
              <Divider />
              <MKTypography variant="h4" fontWeight="light" color="black">
                Awards and Honors
              </MKTypography> <br />
              <blockquote class="relative">
                <svg class="absolute -top-6 -start-8 size-16 text-gray-100 dark:text-neutral-700" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z" fill="currentColor"></path>
                </svg>
                <div class="relative z-10">
                  <p class="text-gray-800 sm:text-xl dark:text-white"><em>
                    Wins and losses are inevitable facets, but the pursuit of learning remains constant — a guiding motto.
                  </em></p>
                </div>
              </blockquote>
              <MKTypography variant="body2" fontWeight="light" color="text">
                This enduring commitment to improvement defines his path, shaping not only his chess prowess but also instilling invaluable life lessons along the way.
              </MKTypography>
              <Grid container alignItems="center">
                <Grid item xs={12} lg={6} sx={{ mt: { xs: 6, lg: 0 } }}>
                  <Stack>
                    <MKBox display="flex" alignItems="center" p={2}>
                      <MKBox
                        width="3rem"
                        height="3rem"
                        variant="gradient"
                        bgColor="info"
                        color="white"
                        coloredShadow="info"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="xl"
                      >
                        <Icon fontSize="small">pattern</Icon>
                      </MKBox>
                      <MKTypography variant="body2" color="text" pl={2}>
                        <b>Recognition</b>: Manush Shah's contributions to chess have been recognized through accolades and awards
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" alignItems="center" p={2}>
                      <MKBox
                        width="3rem"
                        height="3rem"
                        variant="gradient"
                        bgColor="info"
                        color="white"
                        coloredShadow="info"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="xl"
                      >
                        <Icon fontSize="small">settings_overscan</Icon>
                      </MKBox>
                      <MKTypography variant="body2" color="text" pl={2}>
                        <b>Awards Received</b>: He proudly received the Jaydeepsinh Bariya Award (Junior) in 2009-10 and 2010-11.
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" alignItems="center" p={2}>
                      <MKBox
                        width="3rem"
                        height="3rem"
                        variant="gradient"
                        bgColor="info"
                        color="white"
                        coloredShadow="info"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="xl"
                      >
                        <Icon fontSize="small">token</Icon>
                      </MKBox>
                      <MKTypography variant="body2" color="text" pl={2}>
                        <b>Senior Category</b>: Manush also received the Jaydeepsinh Bariya Award in the Senior category in 2016.
                      </MKTypography>
                    </MKBox>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                  <Stack>
                    <MKBox display="flex" alignItems="center" p={2}>
                      <MKBox
                        width="3rem"
                        height="3rem"
                        variant="gradient"
                        bgColor="info"
                        color="white"
                        coloredShadow="info"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="xl"
                      >
                        <Icon fontSize="small">museum_icon</Icon>
                      </MKBox>
                      <MKTypography variant="body2" color="text" pl={2}>
                        <b>Eklavya Award Nomination(Senior) 2024</b>: Nominated for Eklavya Award, highlighting his recognized impact.
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" alignItems="center" p={2}>
                      <MKBox
                        width="3rem"
                        height="3rem"
                        variant="gradient"
                        bgColor="info"
                        color="white"
                        coloredShadow="info"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="xl"
                      >
                        <Icon fontSize="small">fitbit_icon</Icon>
                      </MKBox>
                      <MKTypography variant="body2" color="text" pl={2}>
                        <b>Champion of Champions</b>:  The Ultimate Champion and Unrivaled Collection of 140+ Tournament Trophies".
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" alignItems="center" p={2}>
                      <MKBox
                        width="3rem"
                        height="3rem"
                        variant="gradient"
                        bgColor="info"
                        color="white"
                        coloredShadow="info"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="xl"
                      >
                        <Icon fontSize="small">group3_icon</Icon>
                      </MKBox>
                      <MKTypography variant="body2" color="text" pl={2}>
                        <b>Inspired Victories</b>: countless aspiring chess players have found inspiration, direction, and success.
                      </MKTypography>
                    </MKBox>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
