import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";

import { TypeAnimation } from "react-type-animation";
import PhotoAlbum from "react-photo-album";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import bgGallery from "assets/images/bg-gallery.jpg";

import shubh1 from "assets/images/studs/shubh1.jpg";
import hanya1 from "assets/images/studs/hanya1.jpg";
import hanya6 from "assets/images/studs/hanya6.jpg";
import hari1 from "assets/images/studs/hari1.jpg";
import hari2 from "assets/images/studs/hari2.jpg";
import hari4 from "assets/images/studs/hari4.jpg";
import hanya9 from "assets/images/studs/hanya9.jpg";
import hanya19 from "assets/images/studs/hanya19.jpg";
import hanya22 from "assets/images/studs/hanya22.jpg";
import hanya25 from "assets/images/studs/hanya25.jpg";
import hanya26 from "assets/images/studs/hanya26.jpg";
import kanav1 from "assets/images/studs/kanav1.jpg";
import kanav3 from "assets/images/studs/hanya6.jpg";
import kanav5 from "assets/images/studs/kanav5.jpg";
import kanav10 from "assets/images/studs/kanav10.jpg";
import kanav11 from "assets/images/studs/kanav11.jpg";
import shubh10 from "assets/images/studs/shubh10.jpg";
import hanya12 from "assets/images/studs/hanya12.jpg";
import shubh11 from "assets/images/studs/shubh11.jpg";
import shubh13 from "assets/images/studs/shubh13.jpg";
import shubh16 from "assets/images/studs/shubh16.jpg";
import shubh18 from "assets/images/studs/shubh18.jpg";
import shubh19 from "assets/images/studs/shubh19.jpg";
import tanay1 from "assets/images/studs/tanay1.jpg";
import tanay2 from "assets/images/studs/tanay2.jpg";
import aarsh1 from "assets/images/studs/aarsh1.jpg";
import charvi1 from "assets/images/studs/charvi1.jpg";
import charvi2 from "assets/images/studs/charvi2.jpg";
import imc5 from "assets/images/studs/imc5.jpg";
import imc6 from "assets/images/studs/imc6.jpg";
import imc7 from "assets/images/studs/imc7.jpg";
import imc8 from "assets/images/studs/imc8.jpg";
import imc11 from "assets/images/studs/imc11.jpg";
import imc12 from "assets/images/studs/imc12.jpg";
import imc13 from "assets/images/studs/imc13.jpg";
import imc14 from "assets/images/studs/imc14.jpg";
import imc15 from "assets/images/studs/imc15.jpg";
import imc16 from "assets/images/studs/imc16.jpg";
import imc17 from "assets/images/studs/imc17.jpg";
import imc18 from "assets/images/studs/imc18.jpg";
import imc19 from "assets/images/studs/imc19.jpg";
import imc20 from "assets/images/studs/imc20.jpg";
import imc21 from "assets/images/studs/imc21.jpg";
import imc22 from "assets/images/studs/imc22.jpg";
import imc23 from "assets/images/studs/imc23.jpg";
import imc25 from "assets/images/studs/imc25.jpg";
import imc26 from "assets/images/studs/imc26.jpg";
// import imc27 from "assets/images/studs/imc27.heic";
// import imc28 from "assets/images/studs/imc28.heic";
// import imc29 from "assets/images/studs/imc29.heic";
// import imc30 from "assets/images/studs/imc30.HEIC";
// import imc31 from "assets/images/studs/imc31.HEIC";

import kanav6 from "assets/images/studs/kanav6.jpg";
import M1 from "assets/images/studs/M1.jpg";
import M2 from "assets/images/studs/M2.jpg";
import M3 from "assets/images/studs/M3.jpg";
import M4 from "assets/images/studs/M4.jpg";
import M5 from "assets/images/studs/M5.jpg";
import M6 from "assets/images/studs/M6.jpg";
import M7 from "assets/images/studs/M7.jpg";
import M8 from "assets/images/studs/M8.jpg";
import M9 from "assets/images/studs/M9.jpg";
import M10 from "assets/images/studs/M10.jpg";
import M11 from "assets/images/studs/M11.jpg";
import M12 from "assets/images/studs/M12.jpg";
import M13 from "assets/images/studs/M13.jpg";
import M14 from "assets/images/studs/M14.jpg";
import M15 from "assets/images/studs/M15.jpg";
import M16 from "assets/images/studs/M16.jpg";
import M17 from "assets/images/studs/M17.jpg";
import M18 from "assets/images/studs/M18.jpg";
import M19 from "assets/images/studs/M19.jpg";
import M20 from "assets/images/studs/M20.jpg";
import M21 from "assets/images/studs/M21.jpg";
import M22 from "assets/images/studs/M22.jpg";
import M23 from "assets/images/studs/M23.jpg";
import M24 from "assets/images/studs/M24.jpg";
import M25 from "assets/images/studs/M25.jpg";
import M26 from "assets/images/studs/M26.jpg";
import M27 from "assets/images/studs/M27.jpg";
import M28 from "assets/images/studs/M28.jpg";
// import M29 from "assets/images/studs/M29.png";
// import M30 from "assets/images/studs/M30.jpg";
import imc1 from "assets/images/studs/imc1.jpg";
import imc2 from "assets/images/studs/imc2.jpg";
import imc3 from "assets/images/studs/imc3.jpg";
import imc4 from "assets/images/studs/imc4.jpg";
import imc9 from "assets/images/studs/imc9.jpg";
import imc10 from "assets/images/studs/imc10.jpg";
import imc24 from "assets/images/studs/imc24.jpg";

function Gallery() {
    const { pathname } = useLocation();
    const imcPhotos = [
        {
            src: hanya25,
            width: 5,
            height: 6
        },
        {
            src: shubh1,
            width: 4,
            height: 3
        },
        {
            src: charvi2,
            width: 4,
            height: 6
        },
        {
            src: hanya26,
            width: 4,
            height: 3
        },
        {
            src: imc11,
            width: 4,
            height: 6
        },
        {
            src: imc12,
            width: 4,
            height: 6
        },
        {
            src: imc13,
            width: 4,
            height: 6
        },
        {
            src: imc14,
            width: 4,
            height: 6
        },
        {
            src: imc15,
            width: 4,
            height: 6
        },
        {
            src: imc16,
            width: 4,
            height: 6
        },
        {
            src: imc17,
            width: 4,
            height: 6
        },
        {
            src: imc18,
            width: 4,
            height: 6
        },
        {
            src: imc19,
            width: 4,
            height: 6
        },
        {
            src: imc20,
            width: 4,
            height: 6
        },
        {
            src: imc21,
            width: 4,
            height: 6
        },
        {
            src: imc22,
            width: 4,
            height: 6
        },
        {
            src: imc23,
            width: 4,
            height: 6
        },
        {
            src: hanya1,
            width: 3,
            height: 3
        },
        {
            src: hanya6,
            width: 3,
            height: 5
        },
        {
            src: aarsh1,
            width: 6,
            height: 6
        },
        {
            src: shubh10,
            width: 7,
            height: 8
        },
        {
            src: kanav1,
            width: 4,
            height: 3
        },
        {
            src: imc5,
            width: 4,
            height: 6
        },
        {
            src: imc6,
            width: 4,
            height: 6
        },
        {
            src: imc7,
            width: 4,
            height: 6
        },
        {
            src: imc8,
            width: 4,
            height: 6
        },
        {
            src: hari1,
            width: 3,
            height: 4
        },
        {
            src: hari2,
            width: 3,
            height: 4
        },
        {
            src: charvi1,
            width: 4,
            height: 7
        },
        {
            src: hari4,
            width: 4,
            height: 3
        },
        {
            src: hanya9,
            width: 3,
            height: 4
        },
        {
            src: hanya19,
            width: 4,
            height: 3
        },
        {
            src: hanya22,
            width: 3,
            height: 4
        },
        {
            src: kanav3,
            width: 4,
            height: 3
        },
        {
            src:shubh11,
            width: 4927,
            height: 1000
        },
        {
            src: kanav11,
            width: 4,
            height: 3
        },
        {
            src: shubh13,
            width: 4,
            height: 3
        },
        {
            src: kanav5,
            width: 4,
            height: 6
        },
        {
            src: hanya12,
            width: 5,
            height: 3
        },
        {
            src: shubh16,
            width: 4,
            height: 6
        },
        {
            src: kanav10,
            width: 4,
            height: 6
        },
        {
            src: tanay1,
            width: 4,
            height: 3
        },
        {
            src: shubh19,
            width: 4,
            height: 3
        },
        {
            src: tanay2,
            width: 4,
            height: 6
        },
        {
            src: imc25,
            width: 4,
            height: 6
        },
        {
            src: imc26,
            width: 4,
            height: 6
        },
        // {
        //     src: imc27,
        //     width: 4,
        //     height: 6
        // },
        // {
        //     src: imc28,
        //     width: 4,
        //     height: 6
        // },
        // {
        //     src: imc29,
        //     width: 4,
        //     height: 6
        // },
        // {
        //     src: imc30,
        //     width: 4,
        //     height: 6
        // },
        // {
        //     src: imc31,
        //     width: 4,
        //     height: 6
        // },
        {
            src: shubh18,
            width: 4,
            height: 3
        }
    ];
    const coachPhotos = [
        {
            src: kanav6,
            width: 4,
            height: 7
        },
        {
            src: imc1,
            width: 9,
            height: 9
        },
        {
            src: imc2,
            width: 9,
            height: 9
        },
        {
            src: imc3,
            width: 9,
            height: 9
        },
        {
            src: imc4,
            width: 4,
            height: 9
        },
        {
            src: M1,
            width: 4,
            height: 5
        },
        {
            src: M2,
            width: 4,
            height: 5
        },
        {
            src: M3,
            width: 4,
            height: 3
        },
        {
            src: M4,
            width: 4,
            height: 2
        },
        {
            src: M5,
            width: 4,
            height: 5
        },
        {
            src: M6,
            width: 4,
            height: 3
        },
        {
            src: M7,
            width: 4,
            height: 5
        },
        {
            src: M8,
            width: 4,
            height: 3
        },
        {
            src: M9,
            width: 3,
            height: 2
        },
        {
            src: M10,
            width: 8,
            height: 6
        },
        {
            src: M11,
            width: 9,
            height: 6
        },
        {
            src: M12,
            width: 9,
            height: 8
        },
        {
            src: M13,
            width: 8,
            height: 7
        },
        {
            src: M14,
            width: 8,
            height: 7
        },
        {
            src: M15,
            width: 8,
            height: 7
        },
        {
            src: M16,
            width: 8,
            height: 7
        },
        {
            src: M17,
            width: 8,
            height: 7
        },
        {
            src: M18,
            width: 7,
            height: 9
        },
        {
            src: M19,
            width: 8,
            height: 7
        },
        {
            src: imc10,
            width: 9,
            height: 7
        },
        {
            src: M20,
            width: 8,
            height: 7
        },
        {
            src: M21,
            width: 8,
            height: 7
        },
        {
            src: M22,
            width: 7,
            height: 7
        },
        {
            src: M23,
            width: 6,
            height: 7
        },
        {
            src: M24,
            width: 6,
            height: 7
        },
        {
            src: M25,
            width: 5,
            height: 6
        },
        {
            src: imc9,
            width: 6,
            height: 9
        },
        {
            src: M26,
            width: 5,
            height: 6
        },
        {
            src: M27,
            width: 5,
            height: 6
        },
        {
            src: M28,
            width: 5,
            height: 6
        },
        {
            src: imc24,
            width: 9,
            height: 9
        },
        // {
        //     src: M29,
        //     width: 8,
        //     height: 7
        // },
        // {
        //     src: M30,
        //     width: 8,
        //     height: 7
        // },
    ];
    const [galleryPhotos, setGalleryPhotos] = useState([]);
    useEffect(() => {
        setGalleryPhotos(pathname === '/gallery/imc' ? imcPhotos : coachPhotos);
    }, [pathname])
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="40vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgGallery})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        {pathname === '/gallery/imc' ?
                            <MKTypography
                                variant="h1"
                                color="white"
                                sx={({ breakpoints, typography: { size } }) => ({
                                    [breakpoints.down("md")]: {
                                        fontSize: size["3xl"],
                                    },
                                })}
                            >
                                <TypeAnimation
                                    preRenderFirstString={true}
                                    sequence={[
                                        500,
                                        'IMCHESS GALLERY', // initially rendered starting point
                                        1000,
                                        'IMCHESS WORLD',
                                        1000,
                                        'IMCHESS GALLERY',
                                        1000,
                                        'IMCHESS WORLD',
                                        500,
                                    ]}
                                    speed={50}
                                    // style={{ fontSize: '2em' }}
                                    repeat={Infinity}
                                />
                            </MKTypography> :
                            <MKTypography
                                variant="h1"
                                color="white"
                                sx={({ breakpoints, typography: { size } }) => ({
                                    [breakpoints.down("md")]: {
                                        fontSize: size["3xl"],
                                    },
                                })}
                            >
                                <TypeAnimation
                                    preRenderFirstString={true}
                                    sequence={[
                                        500,
                                        'TEAM GALLERY', // initially rendered starting point
                                        1000,
                                        'COACH WORLD',
                                        1000,
                                        'IMCHESS GALLERY',
                                        1000,
                                        'CHESS MEMORIES',
                                        500,
                                    ]}
                                    speed={50}
                                    // style={{ fontSize: '2em' }}
                                    repeat={Infinity}
                                />
                            </MKTypography>
                        }
                        {pathname === '/gallery/imc' ?
                            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                                Each image tells a story of dedication, growth, and success.
                            </MKTypography> :
                            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                                Step into the world of elite coaching through our IM Coach where expertise knows no borders, only excellence.
                            </MKTypography>}
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <PhotoAlbum layout="columns" photos={galleryPhotos} />
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default Gallery;