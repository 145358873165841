import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";

import { TypeAnimation } from "react-type-animation";
import { Divider } from "@mui/material";
import bgGame from "assets/images/bg-games.jpg";
import GameViewer from "pages/Common/ChessBoard/GameViewer";
import { data } from "./data";

function BestGames() {

    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="40vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgGame})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h4"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            <TypeAnimation
                                preRenderFirstString={true}
                                sequence={[
                                    500,
                                    `Experience the strategic with our collection of Chess Best Games.`, // initially rendered starting point
                                    1000,
                                    `Experience the strategic with our collection of Chess Best Move.`,
                                    1000,
                                    `Experience the strategic with our collection of Chess Best Games.`,
                                    1000,
                                    `Experience the strategic with our collection of Chess Best Move.`,
                                    500,
                                ]}
                                speed={50}
                                repeat={Infinity}
                            />
                        </MKTypography>

                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Where every move is a masterpiece waiting to be discovered!
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <Container>
                    <MKBox component="section" pt={6} my={6}>
                        {data?.map((game, index) =>
                            <div key={index}>
                                <GameViewer game={game?.pgn} oponent1={game?.opponent1} oponent2={game?.opponent2} quote={game?.quote} />
                                <Divider sx={{ backgroundColor: '#86c33a' }} />
                            </div>
                        )}
                    </MKBox>
                    <Divider
                        orientation="vertical"
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "-4px",
                            transform: "translateY(-45%)",
                            height: "90%",
                            backgroundColor: '#86c33a'
                        }}
                    />
                    <MKBox component="section" pt={6} my={6}>

                    </MKBox>
                    <Divider sx={{ backgroundColor: '#86c33a' }} />
                </Container>
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default BestGames;