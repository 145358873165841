import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";

import { TypeAnimation } from "react-type-animation";
import HorizontalTeamCard from 'examples/Cards/TeamCards/HorizontalTeamCard';
import image1 from "assets/images/Multiple/image1.jpg";
import image2 from "assets/images/Multiple/image2.jpg";
import image3 from "assets/images/Multiple/image3.jpg";
import image4 from "assets/images/Multiple/image4.jpg";
import image5 from "assets/images/Multiple/image5.jpg";
import image6 from "assets/images/Multiple/image6.jpg";
import image7 from "assets/images/Multiple/image7.jpg";
import image8 from "assets/images/Multiple/image8.jpg";
import image9 from "assets/images/Multiple/image9.jpg";
import image10 from "assets/images/Multiple/image10.jpg";
import bgPerks from "assets/images/bg-perks.jpg"
function ImchessInfo() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="40vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgPerks})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h4"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            <TypeAnimation
                                preRenderFirstString={true}
                                sequence={[
                                    500,
                                    `Level up your chess game with us!.`, // initially rendered starting point
                                    1000,
                                    `Level up your chess game with titled coach!`,
                                    1000,
                                    `Level up your chess game with us!.`,
                                    1000,
                                    `Level up your chess game with titled coach!`,
                                    500,
                                ]}
                                speed={50}
                                // style={{ fontSize: '2em' }}
                                repeat={Infinity}
                            />
                        </MKTypography>

                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Where dreams become reality and obstacles turn into stepping stones!
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <MKBox mb={1}>
                                <HorizontalTeamCard
                                    image={image5}
                                    name="Live Classes"
                                    position={{ color: "info", label: "Interactive Online and Offline sessions" }}
                                    description="Interactive live classes conducted by expert instructors, allowing students to ask questions and interact in real-time."
                                />
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <MKBox mb={1}>
                                <HorizontalTeamCard
                                    image={image6}
                                    name="Tactics Training"
                                    position={{ color: "info", label: "Learn tactics and stragies" }}
                                    description="Dedicated training modules focusing on specific chess tactics such as forks, pins, skewers, and tactics puzzles for practice."
                                />
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <MKBox mb={{ xs: 1, lg: 0 }}>
                                <HorizontalTeamCard
                                    image={image7}
                                    name="Endgame Studies"
                                    position={{ color: "info", label: "Make a move to increase your endgame strategies" }}
                                    description="In-depth coverage of endgame principles, techniques, and positions, helping students master the most critical phase of the game."
                                />
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <MKBox mb={{ xs: 1, lg: 0 }}>
                                <HorizontalTeamCard
                                    image={image8}
                                    name="Opening Repertoire"
                                    position={{ color: "info", label: "Start with a strong move" }}
                                    description="Guidance on developing a solid opening repertoire tailored to individual playing styles, including analysis of popular opening lines and variations"
                                />
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <MKBox mb={{ xs: 1, lg: 0 }}>
                                <HorizontalTeamCard
                                    image={image4}
                                    name="Game Analysis"
                                    position={{ color: "info", label: "Learn from the mistakes and grow" }}
                                    description="Personalized game analysis by coaches, offering feedback on past games and helping students understand their mistakes and areas for improvement."
                                />
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <MKBox mb={{ xs: 1, lg: 0 }}>
                                <HorizontalTeamCard
                                    image={image10}
                                    name="Chess Mentorship Programs"
                                    position={{ color: "info", label: "Choose your mentor wisely" }}
                                    description="Opportunities for students to receive one-on-one mentorship or coaching from experienced chess players or instructors, tailored to their individual goals and skill levels."
                                />
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <MKBox mb={{ xs: 1, lg: 0 }}>
                                <HorizontalTeamCard
                                    image={image1}
                                    name="Why Choose IMCHESS Academy?"
                                    position={{ color: "info", label: "Learn from a International master" }}
                                    description="Our International Master spills all their winning secrets and experience."
                                />
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <MKBox mb={{ xs: 1, lg: 0 }}>
                                <HorizontalTeamCard
                                    image={image2}
                                    name="Continuous Progress Tracking"
                                    position={{ color: "info", label: "Benefit from regular progress assessments" }}
                                    description="Feedback sessions to track your development, identify areas for improvement, and set achievable goals to keep you motivated."
                                />
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <MKBox mb={{ xs: 1, lg: 0 }}>
                                <HorizontalTeamCard
                                    image={image3}
                                    name="Tournaments Preparations"
                                    position={{ color: "info", label: "Tournament ready! We will sharpen your game so you can bring home the wins." }}
                                    description="Gain a competitive edge with specialized training programs designed to sharpen your skills and readiness for tournaments at all levels."
                                />
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <MKBox mb={{ xs: 1, lg: 0 }}>
                                <HorizontalTeamCard
                                    image={image9}
                                    name="Expert Coaching"
                                    position={{ color: "info", label: "Benefit from personalized guidance" }}
                                    description="Level up with our expert IM coach!
                                Experience rapid improvement with personalized strategies from our Coach, who crafts every lesson to your unique skill level and goals.
                               making progress fast."
                                />
                            </MKBox>
                        </Grid>
                    </Grid>
                </Container>
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default ImchessInfo;